body {
    margin: 0;
    font-family: "Inter", sans-serif;
    background-color: #171717;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    color: #32c5ff;
}
